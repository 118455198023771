import Image from 'next/image'
import React from 'react'

const skeleton = {
  container: 'flex items-center space-x-4 h-full',
  imageContainer: 'relative aspect-square h-full shrink-0',
  image: 'rounded object-cover',
  textContainer: 'flex flex-col space-y-1',
  title: 'font-semibold text-black/80 line-clamp-1',
  description: 'text-sm text-black/60 line-clamp-2',
}

export const UrlExtractMetaPlaceholder = ({ isLoading, message }: { isLoading: boolean; message?: string }) => {
  const className = isLoading ? 'animate-pulse' : ''
  return (
    <div className={[skeleton.container, className].join(' ')}>
      <div
        className={[skeleton.imageContainer, skeleton.image, 'bg-primary/[0.15] light:bg-contrast/10', className].join(
          ' '
        )}
      ></div>
      <div className={[skeleton.textContainer, className].join(' ')}>
        {message ? (
          <div>{message}</div>
        ) : (
          <>
            <div className={['my-0.5 h-4 w-44 rounded bg-primary/10 light:bg-contrast/10', className].join(' ')}></div>
            <div className={['h-2.5 w-44 rounded bg-primary/10 light:bg-contrast/10', className].join(' ')}></div>
            <div className={['h-2.5 w-30 rounded bg-primary/10 light:bg-contrast/10', className].join(' ')}></div>
          </>
        )}
      </div>
    </div>
  )
}

export const UrlExtractMeta = ({
  thumbnailURL,
  title,
  description,
}: {
  thumbnailURL?: string
  title?: string
  description?: string
}) => {
  return (
    <div className={skeleton.container}>
      <div className={skeleton.imageContainer}>
        <Image className={skeleton.image} src={thumbnailURL ?? '/GrayTile.svg'} alt={title ?? ''} fill={true} />
      </div>
      <div className={skeleton.textContainer}>
        <div className={skeleton.title}>{title}</div>
        <div className={skeleton.description}>{description}</div>
      </div>
    </div>
  )
}
