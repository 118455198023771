import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

import { useCurrentUserContext } from '@context/CurrentUserContext'
import IconBell from '@icons/IconBell.svg'
import IconMixLogo from '@icons/IconMixLogo.svg'
import IconProfile from '@icons/IconProfile.svg'
import IconSearch from '@icons/IconSearch.svg'
import UrlHelper from '@lib/UrlHelper'
import { IntoUser } from '@models/IntoUser'
import { selectCurrentFeedItemKey } from '@redux/slices/feedSlice'
import { useAppSelector } from '@redux/store/store'

const NavLink = ({
  href,
  icon,
  className,
  isActive,
  ...linkProps
}: {
  href: string | { pathname: string; query: Record<string, string> }
  icon: React.ReactNode
  className?: string
  isActive?: boolean
} & Partial<LinkProps>) => {
  return (
    <Link href={href} className="pointer-events-auto" {...linkProps}>
      <div
        className={`flex !size-9 h-min fill-primary p-2 text-primary saturate-0 transition-all duration-150 ${
          isActive ? 'opacity-100' : 'opacity-50'
        } ${className}`}
      >
        {icon}
      </div>
    </Link>
  )
}

const TabNav = () => {
  const router = useRouter()
  const { currentUser: currentUserClientSide } = useCurrentUserContext()
  const [currentUser, setCurrentUser] = useState<IntoUser | undefined>(undefined)
  const currentFeedItemKey = useAppSelector(selectCurrentFeedItemKey)

  useEffect(() => {
    setCurrentUser(currentUserClientSide)
  }, [currentUserClientSide])

  const profileHref = currentUser ? UrlHelper.userPath(currentUser.username) : ''

  const currentPath = router.asPath

  const isDiscoverActive = currentPath === '/discover'
  const isNotificationsActive = currentPath === '/notifications'
  const isProfileActive = currentPath === profileHref

  const isMixLogoActive = !isDiscoverActive && !isNotificationsActive && !isProfileActive

  const [homeHref, homeAsPath] = currentFeedItemKey
    ? [
        {
          pathname: '/url/[url_id]',
          query: { url_id: currentFeedItemKey },
        },
        `/`,
      ]
    : ['/home/Home', '/']

  return (
    <div className="pointer-events-none absolute -bottom-px z-10 h-[5.5rem] w-full">
      {/* Navigation links. */}
      <div className="absolute inset-0 z-50 flex items-end justify-around bg-gradient-to-t from-black/70 to-black/0 px-4 py-3">
        <NavLink
          href={homeHref}
          as={homeAsPath}
          icon={<IconMixLogo />}
          className="size-11 !p-1"
          isActive={isMixLogoActive}
        />
        <NavLink href="/discover" icon={<IconSearch />} isActive={isDiscoverActive} />
        <NavLink href="/notifications" icon={<IconBell />} isActive={isNotificationsActive} />
        <NavLink href={profileHref} icon={<IconProfile />} isActive={isProfileActive} />
      </div>

      {/* Blurred background with gradient opacity. */}
      <div
        className="absolute inset-0 bg-gradient-to-t from-black/80 to-black/0 backdrop-blur-2xl"
        style={{
          maskImage: 'linear-gradient(to top, black 0%, transparent 90%)',
          WebkitMaskImage: 'linear-gradient(to top, black 0%, transparent 90%)',
        }}
      />
    </div>
  )
}

export default TabNav
