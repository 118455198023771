import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { FollowButton } from '@common/FollowButton'
import Modal from '@common/Modal'
import { useCurrentUserContext } from '@context/CurrentUserContext'
import { useTrackClick } from '@hooks/analytics/useTrackClick'
import { useIsDesktop } from '@hooks/useIsDesktop'
import UrlHelper from '@lib/UrlHelper'
import urlHelper from '@lib/UrlHelper'
import { useToggleUserFollow } from '@pages/user/hooks/useToggleUserFollow'
import userApi from '@redux/api/userApi'
import { useAppSelector } from '@redux/store/store'

const UserSidePanel = ({ userId }: { userId: number | undefined }) => {
  const { currentUserID, isLoggedIn } = useCurrentUserContext()
  const identifier = useAppSelector(state => state.feed.identifier)
  const router = useRouter()
  const isMobile = !useIsDesktop()
  const { data: user } = userApi.useGetUserDetailsQuery({ userId: userId ?? 0 }, { skip: !userId })
  const [isOwnProfile, setIsOwnProfile] = useState(false)
  const trackClick = useTrackClick()
  const handleFollow = useToggleUserFollow(user)
  const [isLogoutModalVisible, setLogoutModalVisible] = useState(false)

  useEffect(() => {
    setIsOwnProfile(currentUserID === user?.user_id)
  }, [currentUserID, user?.user_id])

  if (!user) return <></>
  return (
    <>
      <div className="flex flex-1 flex-col items-start lg:h-full">
        <div className="flex space-x-10 text-primary sm:flex-col sm:space-x-0 sm:space-y-4">
          <Image
            className="block aspect-square size-32 shrink-0 rounded-full object-contain md:size-40"
            src={user.photo_url ?? ''}
            alt={user.display_name}
            width={400}
            height={400}
          />
          <div className="flex flex-col self-center sm:flex-col sm:items-start sm:space-y-4 sm:self-auto">
            <p className="text-3xl font-bold text-primary lg:text-4xl">{user.display_name}</p>
            <p className="text-primary opacity-50 lg:text-2xl">{`@${user.username}`}</p>
            {user?.bio?.length ? <p className="mt-4 text-primary opacity-75 sm:mt-0">{user.bio}</p> : <></>}
          </div>
        </div>
        {isOwnProfile ? (
          <div className="flex w-full items-center justify-between">
            <Link
              href={'/account'}
              onClick={() => trackClick('userSettings')}
              className="btn btn-primary btn-dot my-8 bg-white font-semibold text-black"
            >
              Settings
            </Link>
            {isMobile && (
              <>
                <button
                  className="btn btn-ghost my-8 text-sm font-semibold"
                  onClick={() => setLogoutModalVisible(true)}
                >
                  Logout
                </button>
                <Modal isVisible={isLogoutModalVisible} onClose={() => setLogoutModalVisible(false)}>
                  <div className="z-10 flex flex-col space-y-3 rounded bg-menu p-6 text-menu">
                    <div className="text-2xl font-semibold">Just to confirm,</div>
                    <div className="text-sm">Would you like to log out?</div>
                    <div className="flex justify-center space-x-6 pt-6">
                      <button className="btn bg-primary/10" onClick={() => setLogoutModalVisible(false)}>
                        Cancel
                      </button>
                      <a href={'/logout'} onClick={() => trackClick('logout')} className="btn btn-secondary">
                        Log out
                      </a>
                    </div>
                  </div>
                </Modal>
              </>
            )}
          </div>
        ) : identifier.slug === 'user' ? (
          <FollowButton className="btn-dot my-8" isFollowing={user.meta.isLiked ?? false} onFollow={handleFollow} />
        ) : (
          <></>
        )}
        <div className="flex self-stretch sm:flex-col">
          <StatLink
            href={UrlHelper.userPath(user.username)}
            count={user?.meta.likesCount}
            label="Likes"
            isActive={router.asPath === urlHelper.userPath(user.username)}
          />
          {isLoggedIn && (
            <StatLink
              href={urlHelper.userSubPath(user.username, 'posts')}
              count={user.meta.curatedCount}
              label="Posts"
              isActive={router.asPath === urlHelper.userSubPath(user.username, 'posts')}
            />
          )}
          <StatLink
            href={urlHelper.userSubPath(user.username, 'following')}
            count={user.meta.followingCount}
            label="Following"
            isActive={router.asPath === urlHelper.userSubPath(user.username, 'following')}
          />
          {isOwnProfile && (
            <>
              <StatLink
                href={urlHelper.userSubPath(user.username, 'followers')}
                count={user.meta.followersCount}
                label="Followers"
                isActive={router.asPath === urlHelper.userSubPath(user.username, 'followers')}
              />
              <StatLink
                href={urlHelper.userSubPath(user.username, 'saved')}
                count={user.meta.savedCount}
                label="Saved"
                isActive={router.asPath === urlHelper.userSubPath(user.username, 'saved')}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

const StatLink = ({
  href,
  count,
  label,
  isActive,
}: {
  href: string
  count?: number
  label: string
  isActive: boolean
}) => (
  <Link
    as={href}
    href={`${href}/?skipUserSSR=true`}
    className={`tab relative flex flex-1 flex-col items-center justify-between p-2 sm:flex-row sm:px-0 sm:leading-[3] ${isActive ? 'tab-active' : 'tab-inactive'} `}
  >
    <span className="font-mono text-sm text-primary sm:order-2">{count?.toLocaleString()}</span>
    <span className="text-xs font-semibold text-primary sm:order-1 sm:text-base">{label}</span>
  </Link>
)

export default UserSidePanel
