import React from 'react'

import UserProfileLayout from '@common/layouts/UserProfileLayout'
import { useCurrentUserContext } from '@context/CurrentUserContext'
import { useIsDesktop } from '@hooks/useIsDesktop'
import { useTestGroupPublisher } from '@hooks/useTestGroupPublisher'
import { useAppSelector } from '@redux/store/store'
import { DownloadAppPeekabooPanel } from '../download/DownloadAppPeekabooPanel'
import LoggedInHeader from '../header/LoggedInHeader'
import LoggedOutHeader from '../header/LoggedOutHeader'

interface LayoutProps {
  children: JSX.Element
}

const PageHeader: React.FC = () => {
  const { isLoggedIn } = useCurrentUserContext()
  const { isDisabled } = useAppSelector(state => state.header)
  const isDesktop = useIsDesktop()
  if (isDisabled) return null
  if (isLoggedIn && isDesktop) return <LoggedInHeader />
  return <LoggedOutHeader />
}

const DesktopLayout = ({ children }: LayoutProps) => {
  useTestGroupPublisher()

  return (
    <>
      <div className="min-h-available relative flex flex-col sm:h-screen">
        <PageHeader />
        <UserProfileLayout>{children}</UserProfileLayout>
        <DownloadAppPeekabooPanel />
      </div>
    </>
  )
}

export default DesktopLayout
