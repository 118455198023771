import { HYDRATE } from 'next-redux-wrapper'

import { createSlice, PayloadAction, prepareAutoBatched } from '@reduxjs/toolkit'
import { AppState } from '../store/store'

type UserPageDataKey = keyof IUserPageData['page']
type UserHasReachedEndKey = keyof IUserPageData['hasReachedEnd']
type UserSliceKey = keyof UserSlice

export interface IUserPageData {
  page: {
    following: number
    followers: number
    likes: number
    saved: number
    posts: number
  }
  hasReachedEnd: {
    following: boolean
    followers: boolean
    likes: boolean
    saved: boolean
    posts: boolean
  }
}

export interface UserSlice {
  userId?: number
  likedByUsername?: string | null
  usersPageData: Record<string, IUserPageData | undefined>
  _metadata: { updatedFields: (keyof UserSlice)[] }
}

const initialUserPageData: IUserPageData = {
  page: {
    following: 1,
    followers: 1,
    likes: 1,
    saved: 1,
    posts: 1,
  },
  hasReachedEnd: {
    following: false,
    followers: false,
    likes: false,
    saved: false,
    posts: false,
  },
}

const initialState: UserSlice = {
  usersPageData: {},
  _metadata: { updatedFields: [] },
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserId: {
      reducer: (state, action: PayloadAction<number | undefined>) => {
        state.userId = action.payload
        state._metadata.updatedFields.push('userId')
      },
      prepare: prepareAutoBatched<number | undefined>(),
    },
    setLikedByUsername: {
      reducer: (state, action: PayloadAction<string | null>) => {
        state.likedByUsername = action.payload
        state._metadata.updatedFields.push('likedByUsername')
      },
      prepare: prepareAutoBatched<string | null>(),
    },
    incrementUserPageData: {
      reducer: (
        state,
        action: PayloadAction<{
          username: string
          type: UserPageDataKey
          incrementBy: number
        }>
      ) => {
        const { username, type, incrementBy } = action.payload

        let userData = state.usersPageData[username]
        if (!userData) {
          userData = { ...initialUserPageData }
          state.usersPageData[username] = userData
        }

        userData.page[type] += incrementBy
        state._metadata.updatedFields.push('usersPageData')
      },
      prepare: prepareAutoBatched<{
        username: string
        type: UserPageDataKey
        incrementBy: number
      }>(),
    },
    setHasReachedEnd: {
      reducer: (
        state,
        action: PayloadAction<{
          username: string
          type: UserHasReachedEndKey
          hasReachedEnd: boolean
        }>
      ) => {
        const { username, type, hasReachedEnd } = action.payload

        let userData = state.usersPageData[username]
        if (!userData) {
          userData = { ...initialUserPageData }
          state.usersPageData[username] = userData
        }

        userData.hasReachedEnd[type] = hasReachedEnd
        state._metadata.updatedFields.push('usersPageData')
      },
      prepare: prepareAutoBatched<{
        username: string
        type: UserHasReachedEndKey
        hasReachedEnd: boolean
      }>(),
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is PayloadAction<AppState> => action.type === HYDRATE,
      (state, action) => {
        const serverApp = action.payload.user

        const fieldsToCheck: UserSliceKey[] = ['userId', 'likedByUsername', 'usersPageData']

        function updateField<K extends keyof UserSlice>(state: UserSlice, field: K, value: UserSlice[K]): void {
          state[field] = value
        }

        fieldsToCheck.forEach(field => {
          if (serverApp._metadata.updatedFields.includes(field) && serverApp[field] !== state[field]) {
            updateField(state, field, serverApp[field])
          }
        })
        state._metadata.updatedFields = []
      }
    )
  },
})

export const selectUserId = (state: AppState) => state.user.userId
export const selectPage = (state: AppState, username: string, type: UserPageDataKey) =>
  state.user.usersPageData[username]?.page[type] ?? 1
export const selectHasReachedEnd = (state: AppState, username: string, type: UserHasReachedEndKey) =>
  state.user.usersPageData[username]?.hasReachedEnd[type] ?? false

export const { setUserId, setLikedByUsername, incrementUserPageData, setHasReachedEnd } = userSlice.actions
export default userSlice.reducer
