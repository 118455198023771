import { useRouter } from 'next/router'

import { useCurrentUserContext } from '@context/CurrentUserContext'
import { useGlobalContext } from '@context/GlobalContexts'

export const shouldUseMobileLayout = (isDesktop: boolean, isLoggedIn: boolean, pathname: string) =>
  !isDesktop && !pathname.includes('/setup') && !pathname.includes('/contest')

export const useMobileLayout = () => {
  const { isLoggedIn } = useCurrentUserContext()
  const {
    browserInfo: { isDesktop },
  } = useGlobalContext()
  const router = useRouter()

  return shouldUseMobileLayout(isDesktop, isLoggedIn, router.pathname)
}
