import { routerReducer } from 'connected-next-router'
import { deleteCookie, getCookie, setCookie } from 'cookies-next'
import { persistReducer, WebStorage } from 'redux-persist'

import { YEAR_TO_SECONDS } from '@lib/time-constants'
import { combineReducers } from '@reduxjs/toolkit'
import { mixApi } from '../api/root'
import analyticsSliceReducer from '../slices/analyticsSlice'
import appSliceReducer from '../slices/appSlice'
import businessEventsReducer from '../slices/businessEventsSlice'
import feedSliceReducer from '../slices/feedSlice'
import headerReducer from '../slices/headerSlice'
import mobileSliceReducer from '../slices/mobileSlice'
import addUrlModalSliceReducer from '../slices/modals/addUrlModalSlice'
import interstitialModalSliceReducer from '../slices/modals/interstitialModalSlice'
import loginModalSliceReducer from '../slices/modals/loginModalSlice'
import notificationsSliceReducer from '../slices/notificationsSlice'
import tweaksReducer from '../slices/tweaksSlice'
import userSliceReducer from '../slices/userSlice'
import videoSliceReducer from '../slices/videoSlice'

const storage: WebStorage = {
  getItem: async key => getCookie(key) || null,
  setItem: async (key, item) =>
    setCookie(key, item, {
      maxAge: YEAR_TO_SECONDS,
      sameSite: true,
      path: '/',
    }),
  removeItem: async key => deleteCookie(key),
}

const persistConfig = {
  key: 'tweaks',
  storage,
  blacklist: ['_metadata', '_persist'],
  timeout: 0,
}

const persistedTweaksReducer = persistReducer(persistConfig, tweaksReducer)

export const rootReducer = combineReducers({
  feed: feedSliceReducer,
  app: appSliceReducer,
  notifications: notificationsSliceReducer,
  user: userSliceReducer,
  tweaks: persistedTweaksReducer,
  router: routerReducer,
  header: headerReducer,
  loginModal: loginModalSliceReducer,
  mobile: mobileSliceReducer,
  addUrlModal: addUrlModalSliceReducer,
  interstitialModal: interstitialModalSliceReducer,
  analytics: analyticsSliceReducer,
  video: videoSliceReducer,
  [mixApi.reducerPath]: mixApi.reducer,
  businessEvents: businessEventsReducer,
})

export type RootState = ReturnType<typeof rootReducer>
