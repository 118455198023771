import { deleteCookie, setCookie } from 'cookies-next'
import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react'

import { BrowserInfo } from '@lib/browser'
import { isBrowser } from '@lib/runtime-environment'

interface IGlobalContextProps {
  browserInfo: BrowserInfo
  pageName: string | undefined
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const GlobalContext = createContext<IGlobalContextProps | undefined>(undefined)

export const GlobalContextProvider = ({
  children,
  pageName,
  browserInfo,
}: {
  children: ReactNode
  pageName: string | undefined
  browserInfo: BrowserInfo
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const isIpadOverride = useMemo(() => {
    const IPAD_MAX_TOUCHPOINTS = 2
    if (isBrowser() && typeof window.navigator !== 'undefined') {
      const navigator = window.navigator
      if (navigator.platform.includes('iPad')) {
        setCookie('is_ipad', true)
        return true
      }
      const isIPad =
        !!navigator.maxTouchPoints &&
        navigator.maxTouchPoints > IPAD_MAX_TOUCHPOINTS &&
        navigator.platform.includes('MacIntel')
      if (isIPad) {
        setCookie('is_ipad', true)
      } else {
        deleteCookie('is_ipad')
      }
      return isIPad
    }
    return false
  }, [])
  if (isIpadOverride) {
    browserInfo.ios = true
    browserInfo.isTablet = true
    browserInfo.isDesktop = false
  }
  const value: IGlobalContextProps = useMemo(
    () => ({
      browserInfo,
      pageName,
      isModalOpen,
      setIsModalOpen,
    }),
    [browserInfo, pageName, isModalOpen, setIsModalOpen]
  )

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
}
export const useGlobalContext = (): IGlobalContextProps => {
  const result = useContext(GlobalContext)
  if (!result) {
    throw new Error('Context used outside of its Provider!')
  }
  return result
}
