import { RootState } from '@redux/store/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface MobileState {
  sidePanel: {
    isOpen: boolean
  }
  bottomSheet: {
    isOpen: boolean
  }
  contrastBackgroundColor: {
    [urlId: string]: 'light' | 'dark'
  }
  loadedImages: {
    [urlId: string]: boolean
  }
}

const initialState: MobileState = {
  sidePanel: {
    isOpen: false,
  },
  bottomSheet: {
    isOpen: false,
  },
  contrastBackgroundColor: {},
  loadedImages: {},
}

const mobileSlice = createSlice({
  name: 'mobile',
  initialState,
  reducers: {
    openSidePanel: state => {
      state.sidePanel.isOpen = true
    },
    closeSidePanel: state => {
      state.sidePanel.isOpen = false
    },
    openBottomSheet: state => {
      state.bottomSheet.isOpen = true
    },
    closeBottomSheet: state => {
      state.bottomSheet.isOpen = false
    },
    setContrastBackgroundColor: (state, action: PayloadAction<{ urlId: string; color: 'light' | 'dark' }>) => {
      state.contrastBackgroundColor[action.payload.urlId] = action.payload.color
    },
    setImageLoaded: (state, action: PayloadAction<{ urlId: string }>) => {
      state.loadedImages[action.payload.urlId] = true
    },
  },
})

export const selectContrastBackgroundColor = (state: RootState, urlId: string): 'light' | 'dark' | undefined => {
  return state.mobile.contrastBackgroundColor[urlId]
}

export const selectImageLoaded = (state: RootState, urlId: string): boolean => {
  return !!state.mobile.loadedImages[urlId]
}

export const {
  openSidePanel,
  closeSidePanel,
  openBottomSheet,
  closeBottomSheet,
  setContrastBackgroundColor,
  setImageLoaded,
} = mobileSlice.actions

export default mobileSlice.reducer
